// @import '@angular/material/prebuilt-themes/deeppurple-amber.css';
/* You can add global styles to this file, and also import other style files */
@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

@import "./assets/scss/material";
@import "./assets/scss/theme";
@import "./assets/scss/utils";
@import "./assets/scss/variables";

@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500&display=swap");

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.spacer {
  flex: 1 1 auto;
}

a {
  color: inherit;
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: var(--accent);
  opacity: 1;
  /* Firefox */
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: var(--accent);
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: var(--accent);
}

.form-control {
  border: none;
  box-sizing: border-box;
  box-shadow: 0 2px 6px #e6e6e6;
  padding: 0.5rem 1rem;
  border-radius: 0.2rem;
  font-size: inherit;
  width: 100%;
  margin-top: 0.2rem;
  border: 1px solid #dedede;
  min-height: 42px;

  * {
    padding: 0.5rem 1rem;
  }
}

.error-container p {
  position: absolute;
  font-size: 0.75rem;
}

.primary-color {
  color: $primary-color;
}
