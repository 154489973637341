@import "./variables";

:root {
  --success: #125030;
  --success-light: #d4edda;
  --danger: #bf360c;
  --danger-light: #f8d7da;
  --warning: #f0b101;
  --warning-light: #fff3cd;
  --info: #0e9edb;
  --info-light: #cce5ff;
  --loader-bg: #8080806e;
}

@mixin formControlStyle {
  width: 100%;
  border-radius: 0.25rem;
  .mat-form-field-underline {
    display: none;
  }

  &.mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label {
    transform: none;
    width: initial;
    opacity: 0;
  }

  .mat-form-field-wrapper {
    padding: 0px !important;
  }

  .mat-form-field-flex {
    padding-top: 0px !important;
    align-items: stretch;
  }

  .mat-form-field-infix {
    margin: 0px !important;
    border: none !important;
  }

  .mat-form-field-label-wrapper {
    display: none;
    padding: 0;
    top: unset;
    bottom: -0.15em;
  }

  .mat-input-element {
    margin-top: 0.5em !important;

    &:focus {
      outline: none;
      border: unset;
      box-shadow: unset;
    }
  }

  .mat-form-field-appearance-fill .mat-select-arrow-wrapper {
    transform: translateY(0%) !important;
  }
}

@media only screen and (max-width: 768px) {
  .flex-direction-xsl-column {
    flex-direction: column;
  }

  .align-xsl-start {
    align-items: start;
  }

  .flex-direction-xsl-column-reverse {
    flex-direction: column-reverse;
  }

  .sm-w-full {
    width: 100% !important;
  }
}
